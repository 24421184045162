import {
  shouldLoadParadigma,
  shouldLoadSegment,
  shouldUseMobileAppBridge,
} from '../utils/configuration-helper';
import * as is from '../utils/is';

// Wrapper for accessing anything under window.analytics
// Or other analytics window objects (paradigma)

export class AnalyticsWrapper {
  getAnalyticsInstance() {
    return window.analytics;
  }

  getUser() {
    const anonIdFunctionToUse = window.GlobalConfiguration.anonymousIdFunctionToUse;
    if (anonIdFunctionToUse === 'segment') {
      return window.analytics?.user;
    }

    if (anonIdFunctionToUse === 'ddna_analytics') {
      return window.ddna_library?.user ? window.ddna_library?.user() : undefined;
    }

    return window.analytics?.user;
  }

  callFuncOnInstances(funcName, ...params) {
    try {
      const logError = (obj) => {
        console.error(`Function ${funcName} is undefined on ${obj}`);
      };

      if (shouldLoadSegment()) {
        window.ddnawrapper.diagnostic.log(`Call ${funcName} on Segment`);
        window.analytics[funcName]
          ? window.analytics[funcName](...params)
          : logError('window.analytics');
      }

      if (shouldLoadParadigma()) {
        window.ddnawrapper.diagnostic.log(`Call ${funcName} on Paradigma`);
        window.ddna_library[funcName]
          ? window.ddna_library[funcName](...params)
          : logError('window.ddna_library');
      }
    } catch (err) {
      console.log(err);
    }
  }

  load(segmentKey, params) {
    window.ddnawrapper.diagnostic.log('load called');
    return this.callFuncOnInstances('load', segmentKey, params);
  }

  ready(callback, isWaitingForUser = false) {
    window.ddnawrapper.diagnostic.log('ready called');

    if (isWaitingForUser) {
      // If waiting for user, check if both libraries are up and call only Segment
      const isSegment = shouldLoadSegment();
      const isParadigma = shouldLoadParadigma();

      if (isSegment && isParadigma) {
        return window.analytics.ready(callback);
      }
    }

    return this.callFuncOnInstances('ready', callback);
  }

  reset() {
    window.ddnawrapper.diagnostic.log('reset called');
    return this.callFuncOnInstances('reset');
  }

  anonymousId() {
    window.ddnawrapper.diagnostic.log('anonymousId called');
    return this.getUser()['anonymousId']();
  }

  setAnonymousId(anonymousId) {
    window.ddnawrapper.diagnostic.log('setAnonymousId called');
    return this.callFuncOnInstances('setAnonymousId', anonymousId);
  }

  userIsReady() {
    if (window.ddnawrapper.diagnostic) {
      window.ddnawrapper.diagnostic?.log('userIsReady called');
    }

    return this.getUser();
  }

  user() {
    window.ddnawrapper.diagnostic.log('user called');
    const user = this.getUser();
    if (typeof user === 'function') {
      return user();
    } else {
      return user;
    }
  }

  page(category, name, properties = {}) {
    window.ddnawrapper.diagnostic.log('page called');

    properties['test_id'] = crypto.randomUUID();
    properties['viewport'] = this.#getViewport();
    properties['screen_resolution'] = this.#getScreenSize();

    if (shouldUseMobileAppBridge()) {
      window.ddnawrapper.diagnostic.log(
        'sending data to trackScreen on OCSMobileAppBridge',
        properties
      );
      const data = JSON.stringify(properties);

      window.OCSMobileAppBridge.callbacks['trackScreenCallback'] = this.trackScreenCallback;

      return window.OCSMobileAppBridge.callHandler('trackScreen', data, 'trackScreenCallback');
    }

    return this.callFuncOnInstances('page', category, name, properties);
  }

  track(event, properties = {}) {
    window.ddnawrapper.diagnostic.log('track called');
    if (
      window.GlobalConfiguration.disabledEvents &&
      window.GlobalConfiguration.disabledEvents !== ''
    ) {
      const values = window.GlobalConfiguration.disabledEvents.split(';');
      if (values?.includes(event)) {
        return;
      }
    }

    properties['test_id'] = crypto.randomUUID();
    properties['viewport'] = this.#getViewport();
    properties['screen_resolution'] = this.#getScreenSize();

    if (shouldUseMobileAppBridge()) {
      properties['event'] = event;
      window.ddnawrapper.diagnostic.log(
        'sending data to trackEvent on OCSMobileAppBridge',
        properties
      );
      const data = JSON.stringify(properties);

      window.OCSMobileAppBridge.callbacks['trackEventCallback'] = this.trackEventCallback;

      return window.OCSMobileAppBridge.callHandler('trackEvent', data, 'trackEventCallback');
    }

    return this.callFuncOnInstances('track', event, properties);
  }

  trackScreenCallback(response) {
    if (response) {
      window.ddnawrapper.diagnostic.log(':::trackScreenCallback::::', response);
    } else {
      window.ddnawrapper.diagnostic.log('trackScreenCallback fired: Response not found');
    }
  }

  trackEventCallback(response) {
    if (response) {
      window.ddnawrapper.diagnostic.log(':::trackEventCallback::::', response);
    } else {
      window.ddnawrapper.diagnostic.log('trackEventCallback fired: Response not found');
    }
  }

  identify(userId, traits = {}, options = {}) {
    if (shouldUseMobileAppBridge()) {
      return;
    }

    window.ddnawrapper.diagnostic.log('identify called');

    if (is.object(userId)) (options = traits), (traits = userId);

    options['test_id'] = crypto.randomUUID();
    options['viewport'] = this.#getViewport();
    options['screen_resolution'] = this.#getScreenSize();

    return this.callFuncOnInstances('identify', userId, traits, options);
  }

  addSourceMiddleware(middlewareFunc) {
    window.ddnawrapper.diagnostic.log('addSourceMiddleware called');
    return this.callFuncOnInstances('addSourceMiddleware', middlewareFunc);
  }

  #getViewport() {
    const width = document.documentElement.clientWidth || 0;
    const height = document.documentElement.clientHeight || 0;

    return `${width} x ${height}`;
  }

  #getScreenSize() {
    const width = window.screen.width;
    const height = window.screen.height;

    return `${width} x ${height}`;
  }
}
