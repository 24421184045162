export function getUrlParams(sParam) {
  let urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (sParam && params && params.length > 0) {
    return params[sParam];
  } else {
    return params;
  }
}

export function getQueryStringValue(key) {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get(key);
}

export function detectAppleMobileDevices() {
  return !!navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
}

export function getHost() {
  const scriptInPage = document.querySelector("script[src*='ddna-wrapper']");
  const scriptLocation = scriptInPage.getAttribute('src');

  try {
    const loc = new URL(scriptLocation);
    let host = loc.hostname;

    const port = loc.port;
    if (port !== 0) {
      host += `:${port}`;
    }

    return host;
  } catch (err) {
    return 'olympics.com';
  }
}

export function getJsonConfigurationUrl() {
  const hostUrl = getHost();

  return `https://${hostUrl}/en/api/v1/ddna-configuration/?v=2`;
}

export function getCookie(cname) {
  let name = cname + '=';
  const documentCookieEncoded = document.cookie
    .split(';')
    .map((cookie) => {
      if (cookie.includes('_gig_llu')) {
        return escape(cookie);
      }

      return cookie;
    })
    .join(';');

  const decodedCookie = decodeURIComponent(documentCookieEncoded);

  let ca = decodedCookie.split(';');

  for (let c of ca) {
    if (c.indexOf(name) !== -1) {
      return c.substring(c.indexOf(name) + name.length, c.length);
    }
  }

  return '';
}

export function deleteCookie(name, path, domain) {
  if (getCookie(name))
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01-Jan-1970 00:00:01 GMT';
}

export function deleteCookieByName(name) {
	document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
}

export function isDisplayAsWebView() {
  const query = new URLSearchParams(window.location.search.toLowerCase());

  return query.get('displayaswebview') == 'true';
}

export function parseMobileAppConsents(consents) {
  if (typeof consents === 'string') {
    return JSON.parse(consents);
  }

  if (typeof consents === 'object') {
    return consents;
  }
}
