import Diagnostic from '../diagnostic/diagnostic.js';
import OneTrust from '../one-trust/d3OneTrustService.js';
import Configuration from '../configuration/index.js';
import UserNation from '../user-nation/index.js';
import {
  detectAppleMobileDevices,
  getJsonConfigurationUrl,
  isDisplayAsWebView,
  parseMobileAppConsents,
} from '../utils/utils.js';
import { AnalyticsWrapper } from '../libraries/analytics-wrapper.js';
import { ConsentScriptUrl, cookieCategoriesValues } from '../config.js';
import { shouldUseMobileAppBridge } from '../utils/configuration-helper.js';
import * as is from '../utils/is.js';

let traitsSet = false;
let trackCachedEvents = [];
let pageCachedEvents = [];
let identifyCachedEvents = [];
let oneTrust = undefined;
let configuration = undefined;
let isWrapperReady = false;

export class DdnaWrapper {
  pageviewInfo = {
    heartbeatId: null,
    heartbeatStartTime: 0,
    prevActiveTime: 0,
    abortController: null,
    heartbeatInterval: null,
    inactivityTimeout: null,
    debouncedTimeout: null,
    inputData: null,
    multiTabChannel: null,
  };

  constructor() {
    window.AnalyticsWrapper = new AnalyticsWrapper();
  }

  async readConfiguration() {
    const jsonReq = await fetch(getJsonConfigurationUrl());
    const jsonConfig = await jsonReq.json();
    // If configuration present, merge it with the default
    if (window.GlobalConfiguration) {
      window.GlobalConfiguration = Object.assign(jsonConfig, window.GlobalConfiguration);
    } else {
      window.GlobalConfiguration = jsonConfig;
    }
  }

  async init() {
    window.ddnawrapper = window.ddnawrapper || {};
    window.ddnawrapper.isReady = this.isReady;
    window.ddnawrapper.analytics = window.ddnawrapper.analytics || {};
    window.ddnawrapper.analytics.track = this.track.bind(this);
    window.ddnawrapper.analytics.page = this.page.bind(this);
    window.ddnawrapper.analytics.identify = this.identify.bind(this);
    window.ddnawrapper.analytics.reset = this.reset;
    window.ddnawrapper.analytics.sendCachedEventsWithTraits = this.sendCachedEventsWithTraits;
    window.ddnawrapper.analytics.consentGiven = this.consentGiven.bind(this);
    window.ddnawrapper.analytics.setImplementationProvider = this.setImplementationProvider;

    window.ddnawrapper.user = window.ddnawrapper.user || {};
    window.ddnawrapper.user.anonymousId = this.anonymousId;

    // Diagnostic
    let diagnostic = new Diagnostic();
    diagnostic.init();

    window.addEventListener('analytics-ready', async () => {
      await this.setTraitsForAllEvents();
    });

    await this.readConfiguration();

    // User nation
    let userNation = new UserNation();
    await userNation.init();

    // Configuration and Segment
    configuration = new Configuration();
    await configuration.init();

    oneTrust = new OneTrust();
    oneTrust.initFunctionsForThirdParties();

    const injectOneTrust = window.GlobalConfiguration.injectOneTrust;

    window.ddnawrapper?.diagnostic?.log(`Inject one trust: ${injectOneTrust}`);

    // Initialize one trust when it's not defined or it's defined and it's set to 'true', otherwise don't initialize
    if (injectOneTrust) {
      // In webviews we are sure the user already gave consent, therefore we initialize OneTrust with needed consent for Segment
      if (isDisplayAsWebView()) {
        await this.handleWebviewConsent();
      } else {
        await oneTrust.init();
        await this.loadCookiesBannerHideStylesScript();
        await this.loadConsentScript();
      }
    }

    isWrapperReady = true;
    window.dispatchEvent(new CustomEvent('ddna-wrapper-ready'));
    window.ddnawrapper?.diagnostic?.log(`ddna wrapper is ready`);
  }

  initHeartbeat() {
    if (!window.GlobalConfiguration.enableWrapperHeartbeat) return;
    window.ddnawrapper?.diagnostic?.log(
      '-- Pageview Heartbeat initialization --',
      this.pageviewInfo
    );

    // cleanup & reset abort controller for events
    if (this.pageviewInfo.abortController) {
      this.pageviewInfo.abortController.abort();
    }
    this.pageviewInfo.abortController = new AbortController();

    const onFocus = () => {
      clearTimeout(this.pageviewInfo.inactivityTimeout);
      // if the "session" has not been reset we resume the previous one otherwise we start a new one
      if (!this.pageviewInfo.heartbeatId) {
        this.pageviewInfo.prevActiveTime = 0;
      }
      this.startHeartbeat();
      // new tab is now focused, remove the inactivity timeout on other tabs
      this.pageviewInfo.multiTabChannel.postMessage('change-active-pageview');
    };

    const onBlur = () => {
      // if the page is hidden we pause the heartbeat
      this.stopPageviewHeartbeat();
    };

    const onBeforeUnload = () => {
      this.resetPageviewHeartbeat();

      // clear events and stop the heartbeat and inactivity timers
      this.pageviewInfo.abortController.abort();
      // in case of this is the  "active tab" with the inactivity timeout we send an event to other tabs saying to start another timer
      if (this.pageviewInfo.inactivityTimeout) {
        // send the multi tab event
        this.pageviewInfo.multiTabChannel.postMessage('active-pageview-closed');
      }
      // cleanup
      this.pageviewInfo.abortController.abort();
    };

    // events to handle heartbeat interval
    window.addEventListener('focus', onFocus.bind(this), {
      signal: this.pageviewInfo.abortController.signal,
    });
    window.addEventListener('blur', onBlur.bind(this), {
      signal: this.pageviewInfo.abortController.signal,
    });
    window.addEventListener('beforeunload', onBeforeUnload.bind(this), {
      signal: this.pageviewInfo.abortController.signal,
    });

    // events to handle inactivity timeout
    window.addEventListener('scroll', this.debounceInactivityTimeout.bind(this), {
      passive: true,
      signal: this.pageviewInfo.abortController.signal,
    });
    window.addEventListener('click', this.debounceInactivityTimeout.bind(this), {
      passive: true,
      signal: this.pageviewInfo.abortController.signal,
    });
    window.addEventListener('keypress', this.debounceInactivityTimeout.bind(this), {
      passive: true,
      signal: this.pageviewInfo.abortController.signal,
    });
    window.addEventListener('touchstart', this.debounceInactivityTimeout.bind(this), {
      passive: true,
      signal: this.pageviewInfo.abortController.signal,
    });

    // multi tab support
    this.pageviewInfo.multiTabChannel = new BroadcastChannel('ddna-wrapper-pageview');

    const onBroadCastMessage = (event) => {
      // in case we hit inactivity time in another page we reset the pageview
      if (event.data === 'inactivity-timeout') {
        window.ddnawrapper?.diagnostic?.log(
          '-- Pageview heartbeat => multi tab inactivity timeout hit event --',
          this.pageviewInfo
        );
        // reset session and send last heartbeat
        this.resetPageviewHeartbeat();
        return;
      }
      // in case of new pageview we reset the inactivity timeout
      if ((event.data === 'new-pageview') | (event.data === 'change-active-pageview')) {
        window.ddnawrapper?.diagnostic?.log(
          '-- Pageview heartbeat => multi tab new active pageview event --',
          this.pageviewInfo
        );
        this.stopInactivityTimeout();
        return;
      }
      // in case we close the active pageview we start the inactivity timeout
      if (event.data === 'active-pageview-closed') {
        window.ddnawrapper?.diagnostic?.log(
          '-- Pageview heartbeat => multi tab active pageview closed event --',
          '-- Active pageview closed multi tab event --',
          this.pageviewInfo
        );
        this.startInactivityTimeout();
      }
    };

    // handling multi-tab support for inactivity
    this.pageviewInfo.multiTabChannel.addEventListener('message', onBroadCastMessage.bind(this), {
      signal: this.pageviewInfo.abortController.signal,
    });

    // if we are in focus start the pageview heartbeat
    if (document.hasFocus()) {
      this.startHeartbeat();
    }
  }

  debounceInactivityTimeout() {
    // reset the inactivity timeout
    clearTimeout(this.pageviewInfo.debouncedTimeout);
    this.pageviewInfo.debouncedTimeout = null;
    // avoid for stacked events (like scrolling) performances issues due to number of events sent in small 0 time
    const onDebounce = () => {
      // if we have the heartbeatid, the user is active so we reset the inactivity timeout
      if (this.pageviewInfo.heartbeatId) {
        window.ddnawrapper?.diagnostic?.log('-- Inactivity timeout resetted --', this.pageviewInfo);
        this.startInactivityTimeout();
      } else {
        // in other case it means we start a new pageview
        this.startHeartbeat();
      }
    };

    this.pageviewInfo.debouncedTimeout = setTimeout(onDebounce.bind(this), 500);
  }

  startInactivityTimeout() {
    clearTimeout(this.pageviewInfo.inactivityTimeout);
    const inactiviteAfter = window.GlobalConfiguration.pageviewDuration ?? 1800000;

    const onInactivity = () => {
      window.ddnawrapper?.diagnostic?.log(
        '-- Pageview Heartbeat Inactivity timeout hit --',
        this.pageviewInfo
      );
      // cleanup current pageview
      this.resetPageviewHeartbeat();
      // send multi tab event to other tabs
      this.pageviewInfo.multiTabChannel.postMessage('inactivity-timeout');
      // cleanup
      this.stopInactivityTimeout();
    };

    this.pageviewInfo.inactivityTimeout = setTimeout(onInactivity.bind(this), inactiviteAfter);
  }

  stopInactivityTimeout() {
    clearTimeout(this.pageviewInfo.inactivityTimeout);
    this.pageviewInfo.inactivityTimeout = null;
  }

  async sendHeartbeat() {
    let activeTime = 0;
    if (!this.pageviewInfo.heartbeatStartTime || this.pageviewInfo.heartbeatStartTime < 1) {
      activeTime = Math.floor(this.pageviewInfo.prevActiveTime / 1000); // if we don't have a start time we send the previous active time, because the pageview is in pause status
    } else {
      activeTime = Math.floor(
        (new Date().getTime() -
          this.pageviewInfo.heartbeatStartTime +
          this.pageviewInfo.prevActiveTime) /
          1000
      );
    }

    window.ddnawrapper?.diagnostic?.log(
      '-- Pageview Heartbeat sent --',
      `id: ${this.pageviewInfo.heartbeatId}`,
      `active_time: ${activeTime}`
    );

    if (!traitsSet) {
      trackCachedEvents.push({
        event: 'Pageview Heartbeat',
        properties: {
          ...this.pageviewInfo.inputData,
          pageview_heartbeat_id: this.pageviewInfo.heartbeatId,
          seconds: activeTime,
        },
      });
    } else {
      await window.AnalyticsWrapper?.track('Pageview Heartbeat', {
        ...this.pageviewInfo.inputData,
        pageview_heartbeat_id: this.pageviewInfo.heartbeatId,
        seconds: activeTime,
      });
    }
  }

  startHeartbeat() {
    this.pageviewInfo.heartbeatStartTime = new Date().getTime();
    // new pageview starts, send the heartbeat and create the id
    if (!this.pageviewInfo.heartbeatId) {
      this.pageviewInfo.heartbeatId = new Date().getTime();
      this.pageviewInfo.prevActiveTime = 0;
      // sending first heartbeat
      this.sendHeartbeat();
    }
    // setup interval
    const intervalTime = window.GlobalConfiguration.heartbeatInterval ?? 10000;
    this.pageviewInfo.heartbeatInterval = setInterval(this.sendHeartbeat.bind(this), intervalTime);

    // start also the inactivity timeout
    this.startInactivityTimeout();
    window.ddnawrapper?.diagnostic?.log('-- Pageview Heartbeat started --', this.pageviewInfo);
  }

  stopPageviewHeartbeat() {
    //  we "save" the active time in case of session resume
    this.pageviewInfo.prevActiveTime += new Date().getTime() - this.pageviewInfo.heartbeatStartTime;
    clearInterval(this.pageviewInfo.heartbeatInterval);
    this.pageviewInfo.heartbeatInterval = undefined;
    // and reset current start time
    this.pageviewInfo.heartbeatStartTime = 0;
    window.ddnawrapper?.diagnostic?.log('-- Pageview Heartbeat stopped --', this.pageviewInfo);
  }

  resetPageviewHeartbeat() {
    // send last heartbeat
    this.sendHeartbeat();
    // clear events and stop the heartbeat and inactivity timers
    clearTimeout(this.pageviewInfo.inactivityTimeout);
    clearInterval(this.pageviewInfo.heartbeatInterval);
    this.pageviewInfo.heartbeatInterval = undefined;
    this.pageviewInfo.inactivityTimeout = undefined;

    // remove current pageview heartbeat
    this.pageviewInfo.prevActiveTime = 0;
    this.pageviewInfo.heartbeatStartTime = 0;
    this.pageviewInfo.heartbeatId = undefined;

    window.ddnawrapper?.diagnostic?.log('--Pageview Heartbeat reset --', this.pageviewInfo);
  }

  async handleWebviewConsent() {
    if (shouldUseMobileAppBridge()) {
      window.ddnawrapper?.diagnostic?.log('OCSMobileAppBridge: handle new webview logic');

      return new Promise((resolve) => {
        /**
         * Consents callback called by the mobile app
         * @param {any} consents data sent by the app
         * @returns {Promise<void>}
         */
        window.getConsentsCallback = async function (consents) {
          window.ddnawrapper?.diagnostic?.log(
            `OCSMobileAppBridge: received response in consents callback: ${consents}`
          );

          if (consents) {
            const parsedConsents = parseMobileAppConsents(consents);

            /** @type {Record<string, string>} */
            const finalConsent = {};

            cookieCategoriesValues.forEach((category) => {
              const consentFromApp = parsedConsents[category];

              if (consentFromApp !== undefined) {
                finalConsent[category] = consentFromApp === true ? '1' : '0';
              } else {
                finalConsent[category] = '0';
              }
            });

            await window.ddnawrapper.analytics.consentGiven(finalConsent);

            resolve();
          } else {
            window.ddnawrapper?.diagnostic?.log(
              `OCSMobileAppBridge: consents not well formed -> pass fallback`
            );

            // Fallback if response is not received
            await window.ddnawrapper.analytics.consentGiven({
              C0001: '1',
              C0002: '1',
              C0003: '0',
              C0004: '0',
              C0005: '0',
            });

            resolve();
          }
        };

        window.OCSMobileAppBridge.callbacks['getConsentsCallback'] = window.getConsentsCallback;

        window.OCSMobileAppBridge.callHandler('getConsents', null, 'getConsentsCallback');
      });
    } else {
      await this.consentGiven({ C0001: '1', C0002: '1', C0003: '0', C0004: '0', C0005: '0' });
    }
  }

  isReady() {
    return isWrapperReady;
  }

  anonymousId() {
    const fetchAnonymousId = () => {
      window.ddnawrapper?.diagnostic?.log(`'anonymousId' method invoked`);

      return window.AnalyticsWrapper?.user().anonymousId();
    };

    return new Promise((resolve, reject) => {
      try {
        if (window.AnalyticsWrapper.userIsReady()) {
          return resolve(fetchAnonymousId());
        } else {
          if (
            window.ddnawrapper.consent.isConsentGiven() &&
            !window.ddnawrapper.consent.isTrackingConsentGiven()
          ) {
            resolve('anonymous_user');
          } else {
            window.addEventListener('analytics-ready', () => resolve(fetchAnonymousId()));
          }
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  async sendCachedEventsWithTraits() {
    if (traitsSet) {
      if (identifyCachedEvents.length > 0) {
        await this.sendIdentifyCachedEvents();
      }

      if (pageCachedEvents.length > 0) {
        await this.sendPageCachedEvents();
      }

      if (trackCachedEvents.length > 0) {
        await this.sendTrackCachedEvents();
      }
    }
  }

  async sendPageCachedEvents() {
    while (pageCachedEvents?.length > 0) {
      const element = pageCachedEvents.pop();
      await window.AnalyticsWrapper?.page(element.category, element.name, element.properties);
    }
  }

  async sendTrackCachedEvents() {
    while (trackCachedEvents?.length > 0) {
      const element = trackCachedEvents.pop();
      await window.AnalyticsWrapper?.track(element.event, element.properties);
    }
  }

  async sendIdentifyCachedEvents() {
    while (identifyCachedEvents?.length > 0) {
      const element = identifyCachedEvents.pop();
      await window.AnalyticsWrapper?.identify(element.userId, element.traits);
    }
  }

  async track(event, properties) {
    if (
      this.pageviewInfo.inactivityTimeout &&
      event !== 'Pageview Heartbeat' &&
      document.hasFocus()
    ) {
      // reset inactivity timeout
      this.debounceInactivityTimeout();
    }
    window.ddnawrapper?.diagnostic?.log(`'track' method invoked, with event name '${event}'.`);
    if (!traitsSet) {
      trackCachedEvents.push({ event: event, properties: properties });
    } else {
      return await window.AnalyticsWrapper?.track(event, properties);
    }
  }

  async page(category, name, properties) {
    if (is.object(category)) (properties = category), (name = category = undefined);
    if (is.object(name)) (properties = name), (name = category), (category = undefined);
    if (is.string(category) && !is.string(name)) (name = category), (category = undefined);

    if (this.pageviewInfo.inactivityTimeout) {
      // reset inactivity timeout
      this.debounceInactivityTimeout();
    }
    // Heartbeat initialization starts here.
    // To make the request we need page event properties, so need to wait for the event to initialize the heartbeat
    this.pageviewInfo.inputData = properties;

    if (!this.pageviewInfo.heartbeatId) {
      this.initHeartbeat();
    }

    window.ddnawrapper?.diagnostic?.log(`'page' method invoked with event name '${name}'`);
    // store data for heartbeat events
    if (!traitsSet) {
      pageCachedEvents.push({ category: category, name: name, properties: properties });
    } else {
      return await window.AnalyticsWrapper?.page(category, name, properties);
    }
  }

  async consentGiven(consent) {
    if (this.pageviewInfo.inactivityTimeout) {
      // reset inactivity timeout
      this.debounceInactivityTimeout();
    }
    window.ddnawrapper?.diagnostic?.log(
      `'consentGiven' method invoked, with data '${JSON.stringify(consent)}'.`
    );

    if (consent) {
      oneTrust.externalConsentGiven(consent);
    }
  }

  async identify(userId, traits = {}) {
    if (this.pageviewInfo.inactivityTimeout) {
      // reset inactivity timeout
      this.debounceInactivityTimeout();
    }
    window.ddnawrapper?.diagnostic?.log(
      `'identify' method invoked, with userId '${JSON.stringify(userId)}'`
    );
    if (!traitsSet) {
      identifyCachedEvents.push({ userId: userId, traits: traits });
    } else {
      return await window.AnalyticsWrapper?.identify(userId, traits);
    }
  }

  async reset() {
    window.ddnawrapper?.diagnostic?.log(`'reset' method invoked`);
    return window.AnalyticsWrapper?.reset();
  }

  async setTraitsForAllEvents() {
    traitsSet = true;
    window.AnalyticsWrapper.addSourceMiddleware(({ payload, next, integrations }) => {
      payload.obj.properties = payload.obj.properties || {};
      payload.obj.properties.traits = window.AnalyticsWrapper.user().traits();
      payload.obj.properties.authenticated = this.isAuthenticationCookieExists();

      payload.obj.context.os = payload.obj.context.os || {};
      payload.obj.context.os.name = this.getOsName();

      next(payload);
    });

    await this.sendCachedEventsWithTraits();
  }

  /**
   * Set the implementation_provider value for the analytics
   * @param {string} implementationProvider
   */
  setImplementationProvider(implementationProvider) {
    window.AnalyticsWrapper.addSourceMiddleware(({ payload, next, integrations }) => {
      payload.obj.properties['implementation_provider'] = implementationProvider;
      next(payload);
    });
  }

  getOsName() {
    if (isDisplayAsWebView()) {
      const iOS = detectAppleMobileDevices();
      return iOS ? 'iOS' : 'android';
    }

    return null;
  }

  isAuthenticationCookieExists() {
    return document?.cookie?.split(';')?.some((it) => it.trim()?.startsWith('glt')) ?? false;
  }

  async loadConsentScript() {
    let globalConfiguration = window.GlobalConfiguration || {};

    if (document.querySelectorAll(`script[src="${ConsentScriptUrl}"]`).length == 0) {
      let bodyscript = document.createElement('script');
      bodyscript.setAttribute('type', 'text/javascript');
      bodyscript.setAttribute('src', ConsentScriptUrl);
      bodyscript.setAttribute('charset', 'UTF-8');
      bodyscript.setAttribute('crossorigin', 'anonymous');
      bodyscript.setAttribute('data-domain-script', globalConfiguration.oneTrustDataDomainScript);

      document.head.appendChild(bodyscript);
    } else {
      window.ddnawrapper?.diagnostic?.log(
        `Consent script '${ConsentScriptUrl}' was preloaded before init on wrapper.`
      );
    }
  }

  async loadCookiesBannerHideStylesScript() {
    const cssStyle = '#onetrust-consent-sdk { display: none; }';
    let bodyscript = document.createElement('style');
    bodyscript.setAttribute('type', 'text/css');
    bodyscript.setAttribute('id', 'one-trust-hide-consent');

    if (bodyscript.styleSheet) {
      // This is required for IE8 and below.
      bodyscript.styleSheet.cssText = cssStyle;
    } else {
      bodyscript.appendChild(document.createTextNode(cssStyle));
    }

    document.head.appendChild(bodyscript);
  }
}

let wrapper = new DdnaWrapper();
wrapper.init();
